import gql from "graphql-tag";

export const SUGGESTIONS = gql`
  query filterWithPaginateSuggestions($criteria: SuggestionFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateSuggestions(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalPages
        totalElements
      }
      content {
        id
        body
        category
        owner {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
        }
        timestamp
      }
    }
  }
`;

export const SUGGESTION_CATEGORIES = gql`
  {
    suggestionCategories {
      id
      name
      color
    }
  }
`;
